import './mint-dialog.scss';
import { Button, Container, Dialog, DialogTitle, IconButton, Slider, TextField } from "@material-ui/core";
import { SnackbarService } from "services/snackbar-service";
import { container } from "tsyringe";
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { clamp } from 'utilities/math';
import { environment } from 'environment';
import { useCallback, ChangeEvent } from 'react';
import { BigNumber, ethers } from 'ethers';
import { roseContract, SaleState } from 'contracts/rose';

interface DialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  account: string | null | undefined;
  web3?: ethers.providers.Web3Provider;
}

const snackbarService = container.resolve(SnackbarService);

export const getMintQuantity = (mintValue: number, mintableNftCount: number) => {
  const maxMintable = Math.min(mintableNftCount, environment.maxMintCount);
  return clamp(mintValue, 1, maxMintable);
}

const MintPausedContents = () => (
  <> 
    <h3>Please Wait</h3>
    <p>Minting has not started, or the contract has been paused. stay tuned to <a href="https://twitter.com/notsofast">NSF</a> and <a href="https://twitter.com/Lotus_Keeper">The Keeper</a></p>
  </>
)

const MintCompleteContents = () => (
  <> 
    <h3>Minting has concluded</h3>
    <p>Thank you for supporting the project, and stay tuned to <a href="https://twitter.com/notsofast">NSF</a> and <a href="https://twitter.com/Lotus_Keeper">The Keeper</a></p>
  </>
)

const CantMintContents = () => (
  <> 
    <h3>You cannot mint any more roses</h3>
    <p>Check your eth balance, and that your wallet has not already minted the maximum amount.</p>
  </>
)

interface MintProps {
  account: string | null | undefined;
  web3?: ethers.providers.Web3Provider;
  ethBalance: BigNumber;
  mintedNftBalance: number;
  remainingSupply: number;
  maxMintCount: number;
  updateWeb3Data: () => Promise<void>;
}

const MintContents = ({ account, web3, ethBalance, mintedNftBalance, remainingSupply, maxMintCount, updateWeb3Data }: MintProps) => {
  const [ mintValue, setMintValue ] = useState(1);
  const [ mintError, setMintError ] = useState<string | null>(null);
  const [ txPending, setTxPending ] = useState(false);

  const mintValueChanged = useCallback((valueStr: string) => {
    const newMintValue = +valueStr;
    setMintValue(newMintValue);

    if (isNaN(newMintValue)) {
      setMintError("Value must be numeric.");
    } else if (newMintValue <= 0 || newMintValue > maxMintCount) {
      setMintError(`Value must be between 1 and ${maxMintCount}.`);
    } else if (newMintValue % 1 !== 0) {
      setMintError("Decimal values are not supported.");
    } else {
      setMintError(null);
    }
  }, [maxMintCount]);

  const mintClicked = useCallback(async () => {
    if ( !account
      || !roseContract
    ) {
      snackbarService.setError('Cant mint, web3 not initialized');
      return;
    }

    const mintCost = environment.mintPrice.mul(mintValue);
    
    if (ethBalance.lt(mintCost)) {
      // we shouldn't ever get here. Just being careful that we don't waste someone's gas.
      snackbarService.setError(`Cannot mint, account contains ${ethBalance} / ${mintCost} $eth.`);
      return;
    }

    setTxPending(true);

    try {
      const mintTx = roseContract.getMintTx(web3, account, mintValue, mintCost) ?? Promise.resolve(null);
      snackbarService.setProgress('Transaction pending...');
      if (!await mintTx) {
        throw new Error('Transaction failed.');
      }
      snackbarService.setSuccess('Transaction succeeded!');
      updateWeb3Data();
    } catch (e: unknown) {
      snackbarService.setError('Could not mint, see console for details.', e);
    }

    setTxPending(false);
  }, [web3, account, ethBalance, mintValue, updateWeb3Data]);
  
  return (
    <>
      <h3>There are {remainingSupply.toLocaleString()} Eternal Rose NFTs remaining, and the mint price is {ethers.utils.formatEther(environment.mintPrice)}.</h3>
      <p>Each wallet can mint up to {environment.maxMintCount} Roses.</p>
      <p>{ mintedNftBalance > 0 ? `You have minted ${mintedNftBalance}` : "You have not minted any" }, and you can mint up to {maxMintCount}{ mintedNftBalance > 0 && ' more' }.</p>
      <TextField defaultValue="1" onChange={(evt) => mintValueChanged(evt.target.value)} error={Boolean(mintError)} helperText={mintError} />    
      {
        maxMintCount &&
          <Button color='primary' disabled={txPending || Boolean(mintError)} onClick={mintClicked}>
            {
              Boolean(mintError)
                ? 'Cannot Mint'
                : `Mint ${mintValue} Rose${ mintValue !== 1 ? 's' : '' }`
            }
          </Button>
      }
    </>
  )
}

export default function MintDialog({ isOpen, closeDialog, account, web3 }: DialogProps) {
  const [ viewReady, setViewReady ] = useState(false)
  const [ remainingSupply, setRemainingSupply ] = useState(0);
  const [ ethBalance, setEthBalance ] = useState(BigNumber.from(0));
  const [ maxMintCount, setMaxMintCount ] = useState(0);
  const [ mintState, setMintState ] = useState(SaleState.NOT_YET);
  const [ mintedNftBalance, setMintedNftBalance ] = useState(0);

  const updateWeb3Data = useCallback(async () => {
    if (!account || !web3) {
      return;
    }

    if (mintState !== SaleState.OK_FINE) {
      const _mintState = await roseContract.getState(web3)
      setMintState(_mintState);

      if (_mintState !== SaleState.OK_FINE) {
        setViewReady(true);
        return;
      }
    }

    const _remainingSupply = environment.maxNftSupply - (await roseContract.totalSupply(web3)).toNumber();
    setRemainingSupply(_remainingSupply);

    if (_remainingSupply === 0) {
      setViewReady(true);
      return;
    }

    const _ethBalance = await web3.getBalance(account);
    const _mintedNftBalance = await roseContract.mintedBalance(web3, account);
    setEthBalance(_ethBalance);
    setMintedNftBalance(_mintedNftBalance);

    let maxMintCount: number;
    const walletMaxMintCount = environment.maxMintCount - _mintedNftBalance;
    const maxMintCost = environment.mintPrice.mul(walletMaxMintCount);

    if (environment.mintPrice.eq(0)) {
      throw new Error('MINT PRICE IS ZERO, CHECK BUILD ENV');
    } else if (_ethBalance.lt(environment.mintPrice)) {
      maxMintCount = 0;
    } else if (_ethBalance.gt(maxMintCost)) {
      maxMintCount = walletMaxMintCount;
    } else {
      maxMintCount = Math.floor(_ethBalance.div(environment.mintPrice).toNumber());
    }

    setMaxMintCount(Math.min(maxMintCount, _remainingSupply))
    setViewReady(true)
  }, [account, web3])

  useEffect(() => {
    if ( !isOpen
      || !account
      || !roseContract) {
      return;
    }
    updateWeb3Data();
  }, [ isOpen, account, updateWeb3Data ]);

  const onClose = useCallback(() => {
    setViewReady(false)
    closeDialog()
  }, [])

  return (
    <>
      <Dialog 
        open={isOpen && viewReady}
        onClose={onClose}
        id='mint-dialog'
      >
        <DialogTitle>
          &nbsp;
          <IconButton
            aria-label="close"
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>  
        </DialogTitle>
        <Container>
          {
            mintState === SaleState.NOT_YET
              ? <MintPausedContents />
              : mintState === SaleState.IT_IS_OVER || remainingSupply === 0
                ? <MintCompleteContents />
                : maxMintCount === 0
                  ? <CantMintContents />
                  : <MintContents {...{account, web3, ethBalance, mintedNftBalance, remainingSupply, maxMintCount, updateWeb3Data}} />
          }
        </Container>
      </Dialog>
    </>
  );
}
