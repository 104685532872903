import ReactDOM from 'react-dom';
import "reflect-metadata";
import "@fontsource/inter";
import "@fontsource/eb-garamond";
import App from './App';
import { createTheme, StylesProvider, ThemeProvider } from '@material-ui/core';
import { CssBaseline } from '@material-ui/core';
import 'index.scss';
// import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#061727',
    },
    primary: {
      main: '#157da7',
    },
    secondary: {
      main: '#1f987e',
    },
    warning: {
      main: '#f557b2',
    },
    error: {
      main: '#ff0068'
    }
  },
  typography: {
    fontFamily: 'Inter',
  }
});

ReactDOM.render(
    <StylesProvider injectFirst >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StylesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
