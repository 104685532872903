import './footer.scss';
import { Container } from '@material-ui/core';
import { openTab } from 'utilities/navigation-utilities';
import { environment } from 'environment';

function Footer() {
  return (
    <div>
        <Container>
          <div className="footer-area">
            <div className='row'>
              <div className='col'>
                <a onClick={() => openTab(environment.forgottenDropUrl)}>Forgotten Drop</a>
                  <a onClick={() => openTab(environment.lotusEpitaphUrl)}>Lotus Epitaph</a>
              </div>
              <div className='col'>
                <p>© 2022</p>
              </div>
            </div>
          </div>
        </Container>
    </div>
  );
}

export default Footer;
