import roseABI from "./rose-abi.json";
import { environment } from "environment";
import { BigNumber, Contract } from "ethers";
import { ethers } from "ethers";

export enum SaleState {
  NOT_YET,
  OK_FINE,
  IT_IS_OVER,
}

class RoseContract {
  private contract: Contract | undefined;

  private getContract(web3: ethers.providers.Web3Provider) {
    if (!this.contract) {
      this.contract = new ethers.Contract(
        environment.roseAddress,
        roseABI,
        web3
      );
    }
    return this.contract;
  }

  public async getState(
    web3: ethers.providers.Web3Provider | undefined
  ): Promise<SaleState> {
    if (!web3) {
      throw new Error("Cannot access method before web3 initialized.");
    }
    const contract = this.getContract(web3);
    const state = await contract.currentSaleState();
    return state as SaleState;
  }

  public async balanceOf(
    web3: ethers.providers.Web3Provider | undefined,
    owner: string | null | undefined
  ): Promise<BigNumber> {
    if (!web3) {
      throw new Error("Cannot access method before web3 initialized.");
    }
    const contract = this.getContract(web3);
    return await contract.balanceOf(owner);
  }

  public async mintedBalance(
    web3: ethers.providers.Web3Provider | undefined,
    owner: string | null | undefined
  ): Promise<number> {
    if (!web3) {
      throw new Error("Cannot access method before web3 initialized.");
    }
    const contract = this.getContract(web3);
    return await contract.mintedPerAddress(owner);
  }

  public async weiPerToken(
    web3: ethers.providers.Web3Provider | undefined,
    from: string | null | undefined
  ): Promise<BigNumber> {
    if (!web3 || !from) {
      throw new Error("Cannot access method before web3 initialized.");
    }
    const contract = this.getContract(web3);
    return await contract.weiPerToken();
  }

  public async totalSupply(
    web3: ethers.providers.Web3Provider | undefined
  ): Promise<BigNumber> {
    if (!web3) {
      throw new Error("Cannot access method before web3 initialized.");
    }
    const contract = this.getContract(web3);
    return await contract.totalSupply();
  }

  public async getMintTx(
    web3: ethers.providers.Web3Provider | undefined,
    from: string | null | undefined,
    quantity: number,
    value: BigNumber
  ): Promise<ethers.ContractReceipt | null> {
    if (!web3 || !from) {
      throw new Error("Cannot access method before web3 initialized.");
    }
    const contract = this.getContract(web3);
    const signerContract = contract.connect(web3.getSigner(from));
    const tx: ethers.ContractTransaction = await signerContract.publicMint(
      quantity,
      {
        value,
      }
    );
    return await tx.wait(1);
  }
}

export const roseContract = new RoseContract();
