import './wallet-connector.scss';
// import { Backdrop, Button, Dialog, DialogTitle } from "@material-ui/core";
import { useEffect, /*useState*/ } from "react";
import { SnackbarService } from "services/snackbar-service";
import { container } from "tsyringe";
import { injected } from 'web3/connectors';
import { getWeb3ErrorMessage } from "web3/errors";
import { useWeb3React } from '@web3-react/core';
// import mmLogo from 'images/metamask-icon.png';

// enum ConnectorName {
//   injected = 'Injected',
//   walletconnect = 'walletconnect',
// }

// const KEY_SELECTED_CONNECTOR = 'selectedConnector';

// const connectorsByName: {[key: string]: any} = {
//   [ConnectorName.injected]: injected,
// }

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
}

const snackbarService = container.resolve(SnackbarService);

export default function WalletConnector({ isOpen, closeDialog }: Props) {
  const { activate } = useWeb3React();
  // const [ isConnecting, setIsConnecting ] = useState(false);

  // HACK: simplifying for now since we only have one provider (injected / metamask). Could prob be custom hook, but this was faster to write.
  useEffect(() => {
    if (!isOpen) return;

    activate(injected, undefined, true)
    .then(() => closeDialog())
    .catch((e: unknown) => snackbarService.setMessage({
      message: getWeb3ErrorMessage(e as Error),
      severity: 'error',
      isClosable: true,
      duration: 5000,
    }));
  }, [isOpen]);

  // const handleActivate = async (connectorName: ConnectorName) => {
  //   setIsConnecting(true);

  //   const connector = connectorsByName[connectorName];

  //   try {
  //     await activate(connector, undefined, true);
  //   } catch (e: unknown) {
  //     snackbarService.setMessage({
  //       message: getWeb3ErrorMessage(e as Error),
  //       severity: 'error',
  //       isClosable: true,
  //       duration: 5000,
  //     })
  //   }
  //   setIsConnecting(false);
  //   closeDialog();
  // }

  return (
    <>
      {/* <Dialog 
        open={isOpen}
        onClose={closeDialog}
        id='wallet-connector'
      >
        <DialogTitle>Connect a Wallet</DialogTitle>
        <Button onClick={() => handleActivate(ConnectorName.injected)}>Metamask<img src={mmLogo} alt="mm icon" /></Button>
      </Dialog>
      <Backdrop style={{zIndex: 1400}} open={isConnecting} /> */}
    </>
  );
}
