import './hero.scss';
import petals from 'images/RosePetals.mp4';
import { Button, Grid } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import rose from 'images/rose-white-64.png';
import { environment } from 'environment';
import { openTab } from 'utilities/navigation-utilities';
import { useWeb3React } from '@web3-react/core';
import WalletConnector from 'components/wallet-connector/wallet-connector';
import { useEagerConnect } from 'web3/hooks';
import MintDialog from 'components/mint-dialog/mint-dialog';
import { ethers } from 'ethers';
import { container } from 'tsyringe';
import { SnackbarService } from 'services/snackbar-service';

interface Props {}

const snackbarService = container.resolve(SnackbarService);

const Hero = (props: Props) => {
    
    useEagerConnect();
    const [walletConnectorIsOpen, setWalletConnectorIsOpen] = useState(false);
    const [mintDialogIsOpen, setMintDialogIsOpen] = useState(false);

    const { 
        account,
        active,
        library: web3,
        chainId,
    } = useWeb3React<ethers.providers.Web3Provider>();

    useEffect(() => {
        if (chainId && chainId !== environment.chainId) {
            console.log(chainId)
            snackbarService.setError(`Invalid chain selected. Please connect to ETH Mainnet.`);
        }
    }, [chainId])

    const connectWallet = useCallback(() => {
        setWalletConnectorIsOpen(true);
    }, [setWalletConnectorIsOpen])

    const mintClicked = useCallback(() => {
        if (!active || !account) {
            setWalletConnectorIsOpen(true);
        } else {
            setMintDialogIsOpen(true);
        }
    }, [active, account, setWalletConnectorIsOpen, setMintDialogIsOpen]);

    return (
        <div id="hero">
            <div className="img-hide">
                <div className="img-wrapper">
                    <video width="2560" height="900" muted autoPlay loop>
                        <source src={petals} type='video/mp4'></source>
                        Your browser does not support video
                    </video>
                    <div className="hor-cap left-fade"></div>
                    <div className="hor-cap right-fade"></div>
                </div>
            </div>
            <div className="ver-cap bottom-fade"></div>
            <div className="content-wrapper">
            <div className="title-area">
                    <div className="row rule-area">
                        <div className="rule" />
                        <img src={rose} alt="rose" />
                        <div className="rule" />
                    </div>
                    <h1>Eternal Roses</h1>
                    <p>The dreary machinations of this troubled plane foiled a daring sacrifice to transmute an item of grand significance.<br />But transcendent power cannot be silenced. Suppress it in one world, and it will bloom in another. Perhaps in another form than you knew.<br />But its soul rings true, and the story renews.</p>
                    <Grid container justifyContent='center' spacing={1}>
                        {
                            environment.mintIsLive && chainId === environment.chainId &&
                            <Grid item xs={12} sm={6}>
                                <Button 
                                    variant="contained" 
                                    className={'gradient-btn-primary'}
                                    onClick={mintClicked}
                                >
                                    Mint
                                </Button>
                            </Grid>
                        }
                        {
                            environment.openSeaUrl &&
                            <Grid item xs={12} sm={6}>
                                <Button 
                                    variant="contained" 
                                    className={'gradient-btn-secondary'}
                                    onClick={() => openTab(environment.openSeaUrl)}
                                >
                                    OpenSea
                                </Button>
                            </Grid>
                        }
                        {
                            environment.looksRareUrl &&
                            <Grid item xs={12} sm={6}>
                                <Button 
                                    variant="contained" 
                                    className={'gradient-btn-secondary'}
                                    onClick={() => openTab(environment.looksRareUrl)}
                                >
                                    LooksRare
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </div>
                <div className="wallet-area">
                    {
                        environment.mintIsLive &&
                        <Button variant="contained" color="primary" onClick={connectWallet}>
                            {active && account ? account.replace(/^0x(.{4}).+(.{4})$/i, '$1...$2') : 'Connect Wallet'}
                        </Button>
                    }
                </div>
            </div>
            <WalletConnector 
                isOpen={walletConnectorIsOpen}
                closeDialog={() => setWalletConnectorIsOpen(false)}
            />
            <MintDialog
                isOpen={mintDialogIsOpen}
                closeDialog={() => setMintDialogIsOpen(false)}
                {...{ account, web3 }}
            />
        </div>
    );
}

export default Hero;
