import './App.scss';
import Footer from 'components/footer/footer';
import Hero from 'components/hero/hero';
import RoseSnackbar from 'components/rose-snackbar/rose-snackbar';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { Web3ReactProvider} from '@web3-react/core'
import { ethers } from 'ethers';

const getWeb3Provider = (provider: any, connector?: Required<Web3ReactContextInterface>['connector']) => {
  const web3 = new ethers.providers.Web3Provider(provider);
  provider.send("eth_requestAccounts", []);
  return web3;
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getWeb3Provider}>
        <Hero />
        <Footer />
        <RoseSnackbar />
    </Web3ReactProvider>
  );
}

export default App;
