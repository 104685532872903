import { IconButton, Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import { useCallback, useEffect, useState } from 'react';
import { Subject, takeUntil } from 'rxjs';
import { container } from 'tsyringe';
import { SnackbarService } from 'services/snackbar-service';
import './rose-snackbar.scss';
import { SyncLoader } from 'react-spinners';
import { SnackbarMessage } from 'models/snackbar-message';

const snackbarService = container.resolve(SnackbarService);
const defaultState: SnackbarMessage & { isOpen: boolean } = {
  message: '',
  isOpen: false,
}

function RoseSnackbar() {
  const [ state, setState ] = useState<SnackbarMessage & { isOpen: boolean }>(defaultState);
  
  const destroy$ = new Subject<void>();

  useEffect(() => {
    /**
     * OnMount
     */
    snackbarService.message$
    .pipe(
      takeUntil(destroy$)
    )
    .subscribe((message) => {
      setState((_) => {
        return {
          isOpen: true,
          ...message
        };
      })
    });

    snackbarService.close$
    .pipe(
      takeUntil(destroy$)
    )
    .subscribe(() => {
      handleClose();
    });

    /**
     * OnUnmount
     */
    return (() => {
      destroy$.next();
    })
  }, []); // eslint-disable-line

  const handleClose = useCallback(() => {
    setState((prev) => {
      return { 
        ...prev,
        isOpen: false, 
      };
    })
  }, [setState]);

  const spinner = (
    <SyncLoader size={10} />
  );

  const closeIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const snackbarAction = (
    <>
      {state.isProgress ? spinner : undefined}
      {state.isClosable ? closeIcon : undefined}
    </>
  )

  return state.severity ? (
    <Snackbar 
      open={state.isOpen}
      autoHideDuration={state.duration}
      onClose={state.isClosable ? handleClose : undefined}
    >
      <Alert 
        severity={state.severity} 
        onClose={state.isClosable ? handleClose : undefined} 
      >
        {state.message}
        {state.isProgress ? spinner : undefined}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar 
      open={state.isOpen}
      message={state.message}
      autoHideDuration={state.duration}
      onClose={handleClose}
      action={snackbarAction}
    />
  )
}

export default RoseSnackbar;
