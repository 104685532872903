import { SnackbarMessage } from "models/snackbar-message";
import { Subject } from "rxjs";
import { singleton } from "tsyringe";

@singleton()
export class SnackbarService {
    private _message$ = new Subject<SnackbarMessage>();
    private _close$ = new Subject<void>();

    public get message$() { return this._message$ }
    public get close$() { return this._close$ }

    public setMessage(message: SnackbarMessage) {
        // HACK: for some reason, simply firing a new message on top of an existing one closes the snackbar. weird.
        this._close$.next();
        setTimeout(() => this._message$.next(message), 100);
    }

    public setProgress(message: string) {
        this.setMessage({
            message,
            severity: 'info',
            isProgress: true,
            isClosable: false,
        });
    }

    public setSuccess(message: string) {
        this.setMessage({
            message,
            severity: 'success',
            isClosable: true,
            duration: 5000,
        });
    }

    public setError(message: string, e?: unknown) {
        e && console.error(e);

        this.setMessage({
            message,
            severity: 'error',
            isClosable: true,
            duration: 5000,
        });
    }

    public close() {
        this._close$.next();
    }
}
