import { BigNumber } from "ethers";

export const environment = {
  chainId: +(process.env.REACT_APP_CHAIN_ID ?? 1),
  roseAddress: process.env.REACT_APP_ROSE_ADDR ?? "",
  maxNftSupply: +(process.env.REACT_APP_MAX_NFT_COUNT ?? 2500),
  maxMintCount: +(process.env.REACT_APP_MAX_MINT_COUNT ?? 60),
  mintPrice: BigNumber.from(process.env.REACT_APP_MINT_PRICE ?? "0"),
  mintIsLive: process.env.REACT_APP_MINT_LIVE?.toLowerCase() === "true",
  openSeaUrl: process.env.REACT_APP_OPENSEA_URL,
  looksRareUrl: process.env.REACT_APP_LOOKSRARE_URL,
  forgottenDropUrl: process.env.REACT_APP_FORGOTTENDROP_URL,
  lotusEpitaphUrl: process.env.REACT_APP_EPITAPH_URL,
};
